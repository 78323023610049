import { debounce } from "lodash";
import mediator from "@tvg/mediator";
import { getWalletBalances } from "@tvg/sh-utils/walletUtils";
import {
  getAuthTokenCookie,
  getSportsbookRegionCookie
} from "@tvg/sh-utils/sessionUtils";
import { NullaryFn } from "@tvg/ts-types/Functional";
import { isXSell, onTriggerGeolocation } from "@tvg/sh-utils/mobileUtils";
import {
  onTriggerLogout,
  onRefreshSession,
  subscribeLoginComplete,
  subscribeLoginCancelled,
  subscribeAuthDetailsUpdated,
  subscribeRefreshSession,
  subscribeLogoutComplete,
  subscribeAccountAndWalletClosed,
  subscribeOpenAWComplianceModal,
  subscribeOpenAWPage
} from "./mobileUtils";
import {
  completeLogin,
  getUserProfile,
  handleRefreshSession
} from "../session/login";
import { clearSession } from "../session/logout";
import { openAccountWalletModal } from "../redux/actions";
import { Props } from "../types";
import { IAWFlows } from "../sdkWebTypes";

export const getCompleteLoginOptions = (
  userName: string,
  enforceAcceptTerms: boolean = false
) => ({
  userName,
  enforceAcceptTerms
});

export const accountWalletSubscriptions = (
  getActualProps: NullaryFn<Props>,
  cleanLoginCallback: NullaryFn<void>,
  awFlowsSDK?: IAWFlows
) => {
  subscribeLoginComplete((authToken: string) => {
    const props = getActualProps();
    completeLogin(
      authToken,
      props.dispatch,
      props.geolocation.state || getSportsbookRegionCookie(),
      getCompleteLoginOptions(props.user.userName)
    );
  });

  subscribeLoginCancelled(cleanLoginCallback);

  subscribeAccountAndWalletClosed(() => {
    const props = getActualProps();
    const authToken = getAuthTokenCookie();

    if (!authToken || !props.user.isLogged) {
      if (props.user.isLogged) {
        clearSession(props);
      }

      return;
    }

    if (props.user.isVerified) {
      getUserProfile(authToken, props.geolocation.state, props.dispatch);
      if (!props.user.isTermsAccepted) {
        mediator.base.dispatch({ type: "GET_USER_JURISDICTION" });
      }
    } else {
      completeLogin(
        authToken,
        props.dispatch,
        props.geolocation.state || getSportsbookRegionCookie(),
        getCompleteLoginOptions(props.user.userName)
      );
    }

    getWalletBalances(props.dispatch);
  });

  subscribeLogoutComplete(() => {
    const props = getActualProps();
    clearSession(props);
  });

  if (isXSell()) {
    subscribeAuthDetailsUpdated((authToken: string, props: Props) => {
      completeLogin(
        authToken,
        props.dispatch,
        props.geolocation.state,
        getCompleteLoginOptions(props.user.userName)
      );
    }, getActualProps);
  } else {
    subscribeRefreshSession((authToken: string) => {
      const props = getActualProps();
      handleRefreshSession(
        authToken,
        props.geolocation.state || getSportsbookRegionCookie(),
        props.user.isLogged,
        props.dispatch,
        getCompleteLoginOptions(props.user.userName),
        props.enableRetryGeocomply
      );

      if (props.geolocationStatus === "LOCATION_FAILURE") {
        onTriggerGeolocation("Retry");
      }
    });

    subscribeOpenAWPage(getActualProps, (dispatch, data) =>
      dispatch(openAccountWalletModal(data))
    );
  }

  // sdk web
  mediator.base.subscribe("TRIGGER_LOGOUT", () => {
    onTriggerLogout(awFlowsSDK);
    mediator.base.dispatch({ type: "FETCH_QUICKLINKS" });
  });

  mediator.base.subscribe(
    "TRIGGER_REFRESH_SESSION",
    debounce(() => {
      const { user } = getActualProps();
      if (user.isLogged) {
        onRefreshSession(awFlowsSDK);
      }
    }, 5000)
  );

  // compliance
  subscribeOpenAWComplianceModal(getActualProps, (dispatch, data) =>
    dispatch(openAccountWalletModal(data))
  );
};
